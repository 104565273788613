import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';
import { snapChatWidgetCsp } from '../../server/csp/providers/snapChatWidgetCsp';
import {
  avalonBodyDefaultBackgroundColorCss,
  avalonHeaderCss,
  avalonSdsmHeaderCss,
} from './styles/avalonStyles';

export const config: SiteConfiguration = {
  domainName: 'www.spectacles.com',
  theme: {
    defaultPageBackgroundColor: 'Black',
  },

  siteMap: {
    customPaths: {
      // Top level domain, should include cookie-settings in site map
      '/cookie-settings': { isLocalized: true },
    },
  },
  trackingSettings: {
    cookieDomain: 'spectacles.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.AVALON },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'spectacles-com',
        dsn: 'https://3129a5f13e664891bc7a65cac4f65640@sentry.sc-prod.net/74',
      },
    ],
  },

  globalStyles: avalonBodyDefaultBackgroundColorCss,

  headerProps: {
    // MWP Header styles.
    className: avalonHeaderCss,
  },

  globalNavProps: {
    // SDSM Header styles.
    className: avalonSdsmHeaderCss,
    defaultGroupKey: 'spectacles',
  },

  persistQueryParamsDomains: ['api.spectacles.com', 'orders.spectacles.com'],
  persistentQueryParams: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'],

  enableArkoseOnForms: true,

  csp: snapChatWidgetCsp,

  // Enable other locales in Staging & prepare Lilt integration, but don't use in Production
  localeFilter: ['en-US'],
};
