import { cx } from '@emotion/css';
import { getDprSrcSetSettingsByHeight, useContentfulImages } from '@snapchat/mw-contentful-client';
import { Alignment, Picture, Video } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { combineImageSources } from '../../../../utils/combineImageSources';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import { avalonProductHeroQuery } from './AvalonProductHero.query';
import {
  heroContainerCss,
  heroContentCss,
  heroTitleCss,
  imageCss,
  imagePictureCss,
  mediaAspectRatioCss,
  mediaContainerCss,
  textCenterCss,
  textEndCss,
} from './AvalonProductHero.styles';
import type { AvalonProductHeroProps } from './types';

export const AvalonProductHero: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<AvalonProductHeroProps, ContentfulIdVariable>(
    avalonProductHeroQuery,
    { variables: { id: props.id } }
  );

  const { getImageSources } = useContentfulImages();

  if (!data) return <LoadingPlaceholder />;

  const { title, body, textAlignment, media } = data.avalonProductHero;

  const desktopMedia = media.media;
  const mobileMedia = media.mobileMedia;
  const mediaType = media.__typename;

  let imageSrcs;

  if (mediaType === 'Image') {
    imageSrcs = combineImageSources({
      desktop: getImageSources(
        desktopMedia.url,
        getDprSrcSetSettingsByHeight(800, desktopMedia.height ?? 0)
      ),
      mobile: getImageSources(
        mobileMedia?.url ?? desktopMedia.url,
        getDprSrcSetSettingsByHeight(800, mobileMedia?.height ?? 0)
      ),
    });
  }

  return (
    <section className={cx('avalon-product-hero', heroContainerCss)}>
      <div
        className={cx(heroContentCss, {
          [textCenterCss]: textAlignment === Alignment.Center,
          [textEndCss]: textAlignment === Alignment.End,
        })}
      >
        {title && <h1 className={heroTitleCss}>{title}</h1>}
        {body && renderRichText(body)}
      </div>
      <div className={mediaContainerCss}>
        <div className={mediaAspectRatioCss}>
          {mediaType === 'Image' && (
            <Picture
              altText={desktopMedia.description}
              imgClassName={imageCss}
              imgSrcs={imageSrcs}
              className={imagePictureCss}
            />
          )}
          {mediaType === 'Video' && (
            <Video
              isBackgroundVideo
              source={desktopMedia.url}
              sourceType={desktopMedia.contentType}
              mobileSource={mobileMedia?.url}
              mobileSourceType={mobileMedia?.contentType}
            />
          )}
        </div>
      </div>
    </section>
  );
};
