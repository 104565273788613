import type { WidthType } from '@snapchat/snap-design-system-marketing';
import { Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentDataProps } from '../../../../components/Content';
import { Content } from '../../../../components/Content';
import { logError } from '../../../../helpers/logging';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { renderRichTextMultiLineWithMarkings } from '../../../../utils/renderText/renderRichText';
import { AvalonForm } from './AvalonForm';
import { avalonFormBlockQuery } from './AvalonFormBlock.query';
import type { AvalonFormBlockDataHandlerProps, AvalonFormProps } from './AvalonFormBlock.types';

type BlockContentProps = ContentDataProps | AvalonFormProps;

const BlockContent: FC<BlockContentProps> = props => {
  switch (props.__typename) {
    case 'Content': {
      return <Content {...(props as ContentDataProps)} />;
    }

    case 'AvalonForm': {
      return <AvalonForm {...(props as AvalonFormProps)} />;
    }

    default: {
      logError({
        component: 'Block',
        message: `Unknown content type: ${props.__typename} with id: ${props.sys.id}`,
      });

      return null;
    }
  }
};

/**
 * Wrapper Block component for the `AvalonForm` component. See that component for details on
 * business case.
 */
export const AvalonFormBlock: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<AvalonFormBlockDataHandlerProps, ContentfulIdVariable>(
    avalonFormBlockQuery,
    { variables: { id: props.id } }
  );

  if (!data?.avalonFormBlock) return null;

  const {
    contentsCollection,
    title,
    subtitle,
    anchorId,
    maxColumns,
    titleAlignment,
    titleAlignmentMobile,
    widthStyle,
    backgroundColor,
  } = data.avalonFormBlock;
  const children = contentsCollection?.items ?? [];

  return (
    <BlockSDS
      anchorId={anchorId}
      maxColumns={maxColumns}
      subtitle={renderRichTextMultiLineWithMarkings(subtitle)}
      title={renderRichTextMultiLineWithMarkings(title)}
      titleAlignment={titleAlignment}
      titleAlignmentMobile={titleAlignmentMobile}
      widthStyle={widthStyle as WidthType}
      backgroundColor={backgroundColor}
    >
      {children.map(item => (
        <BlockContent key={item.sys.id} {...item} />
      ))}
    </BlockSDS>
  );
};
