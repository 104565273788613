import { gql } from '@apollo/client';
import type { Content, Form } from '@snapchat/mw-contentful-schema';
import type { Alignment, BackgroundColor } from '@snapchat/snap-design-system-marketing';

import { fragments as contentFragments } from '../../../../components/Content';
import { formFragments } from '../../../../components/Form';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import type { RichText } from '../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

const avalonFormFragment = gql`
  fragment AvalonFormAll on AvalonForm {
    ...ContentfulSysId
    endpoint
    form {
      ...FormAll
    }
  }
  ${contentfulSysIdFragment}
  ${formFragments.all}
`;

export const avalonFormBlockQuery = gql`
  query AvalonFormBlockQuery($id: String!, $preview: Boolean!, $locale: String!) {
    avalonFormBlock(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      title {
        json
      }
      subtitle {
        json
      }
      titleAlignment
      titleAlignmentMobile
      contentsCollection(limit: 5) {
        items {
          ... on Content {
            ...ContentAll
          }
          ... on AvalonForm {
            ...AvalonFormAll
          }
        }
      }
      backgroundColor
      maxColumns
      widthStyle
      anchorId
    }
  }
  ${contentfulSysIdFragment}
  ${contentFragments.all}
  ${formFragments.all}
  ${avalonFormFragment}
`;

export interface AvalonForm extends ContentfulSysProps {
  contentfulDescription?: string;
  endpoint?: string;
  form?: Form;
}

export type AvalonFormBlockContent = Content | AvalonForm;

export interface AvalonFormBlock extends ContentfulSysProps {
  contentfulDescription?: string;
  title?: RichText;
  subtitle?: RichText;
  titleAlignment?: Alignment;
  titleAlignmentMobile?: Alignment;
  contentsCollection?: Items<AvalonFormBlockContent>;
  backgroundColor?: BackgroundColor;
  maxColumns?: 1 | 2 | 3;
  widthStyle?: string;
  anchorId?: string;
}
