import type { FormBody } from '@snapchat/snap-design-system-marketing';
import { validations } from '@snapchat/snap-design-system-marketing';
import clone from 'lodash-es/clone';
import isEqual from 'lodash-es/isEqual';
import merge from 'lodash-es/merge';
import { type FC, useCallback, useEffect, useRef, useState } from 'react';

import type { FormDataProps, OnFormInit, OnValueChange } from '../../../../components/Form';
import { Form } from '../../../../components/Form/Form';
import { logError } from '../../../../helpers/logging';
import type { CustomArkoseClient } from '../../../../hooks/useArkose';
import { useArkose } from '../../../../hooks/useArkose';
import type { AvalonFormProps } from './AvalonFormBlock.types';

/**
 * Field definition for Draft form submissions. Purposely storing this in code as this component is
 * VERY custom logic - no reason to make it generic or configurable.
 */
interface DraftPayload {
  form_category: string;
  first_name: string;
  last_name: string;
  email: string;
  receive_updates: boolean;
}

const initialDraftPayload: DraftPayload = {
  form_category: '',
  first_name: '',
  last_name: '',
  email: '',
  receive_updates: false,
};

/** Used to identify when a relevant field has been modified in the underlying form */
const relevantFieldNames = new Set(Object.keys(initialDraftPayload));

/**
 * Used to detect when the user has provided sufficient information in the form for us to send
 * re-engagement marketing emails if they abandon the form without submitting.
 *
 * Once the user has populated the contact information fields and checked the `receive_updates`
 * legal signoff, this component will send that information to a dedicated API endpoint. No other
 * form fields are included in the payload.
 *
 * If the user makes a subsequent change to the contact fields or legal sign-off after this was
 * sent, the component will send additional payloads with the changes. This to capture edge cases
 * such as:
 *
 * - User mistyped their contact information
 * - User unchecks the `receive_updates` legal signoff (if this occurs we cannot send re-engagement
 *   emails)
 *
 * Marketing will dedup payloads received via this component within Salesforce to ensure that only
 * the latest information for a given user is used for the re-engagement emails.
 */
export const AvalonForm: FC<AvalonFormProps> = ({ endpoint, form }) => {
  // Tracks current form state (may or may not be valid)
  const [draftPayload, setDraftPayload] = useState<DraftPayload>(initialDraftPayload);
  // Tracks last valid form state sent to server
  const [priorPayload, setPriorPayload] = useState<DraftPayload>();

  const arkoseRef = useRef<CustomArkoseClient>();

  const arkosePromiseResolveRef = useRef<(value: FormBody) => void>();
  const arkosePromiseRejectRef = useRef<(reason: string) => void>();

  useArkose({
    arkoseClientRef: arkoseRef,
    enableArkose: true,

    onCompleted: token => {
      arkosePromiseResolveRef.current?.({ arkoseToken: token });
    },
    onError: error => {
      logError({ component: 'AvalonForm', error, message: 'Arkose error', action: 'Validate' });
      arkosePromiseRejectRef.current?.(error ?? 'Arkose error');
    },
  });

  const getArkoseToken = useCallback(async () => {
    arkoseRef.current?.run();

    return new Promise<FormBody>((resolve, reject) => {
      arkosePromiseResolveRef.current = resolve;
      arkosePromiseRejectRef.current = reject;
    });
  }, []);

  const parseFormBodyForDraftPayload = (formBody: FormBody): DraftPayload => ({
    form_category: (formBody.form_category ?? '') as string,
    first_name: (formBody.first_name ?? '') as string,
    last_name: (formBody.last_name ?? '') as string,
    email: (formBody.email ?? '') as string,
    receive_updates: (formBody.receive_updates as boolean | undefined) ?? false,
  });

  /** Form Init handler passed to standard Form component. Populates `draftPayload` state */
  const onFormInit = useCallback<OnFormInit>(formBody => {
    const payload = parseFormBodyForDraftPayload(formBody);
    setDraftPayload(payload);
  }, []);

  /** Change handler passed to standard Form component. Populates `draftPayload` state */
  const onValueChange = useCallback<OnValueChange>((formBody, lastChangedFieldName, isInit) => {
    // filter to changes to relevant fields
    // NOTE: we don't use this for form initialization as this callback is fired by SDS-M
    //       and does not include values from localstorage, query strings, etc.
    if (isInit || !relevantFieldNames.has(lastChangedFieldName ?? '')) return;

    const payload = parseFormBodyForDraftPayload(formBody);
    setDraftPayload(payload);
  }, []);

  /**
   * Simple validation function used to ensure only valid form data is sent to the server. A form
   * payload is only considered valid for initial payload if all four text fields are populated and
   * the use has agreed to the legal signoff checkbox. However, we need to track when a user opts
   * out of legal signoff, so we drop that validation after the first payload is sent.
   *
   * @param input The form data to be validated
   * @param isInitialPayload Controls validation behavior for `receive_updates` field.
   */
  const isValidPayload = (input: DraftPayload, isInitialPayload: boolean): boolean => {
    if (!input.form_category) return false;

    if (!input.first_name) return false;

    if (!input.last_name) return false;

    if (!input.email || !validations.Email.test(input.email)) return false;

    if (isInitialPayload && !input.receive_updates) return false;

    return true;
  };

  /**
   * Sends form data to API endpoint. Logs any errors that occur without UI representation.
   *
   * Does not retry.
   */
  const trySubmitDraftApplication = useCallback(
    async (input: DraftPayload): Promise<void> => {
      if (!endpoint) return;

      try {
        const arkoseToken = (await getArkoseToken().catch(() => ({}))) ?? {};
        const formBody = merge(clone(input), arkoseToken);

        const response = await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify(formBody),
          headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
          const payload = await response.text();
          throw new Error(
            `Form submit failed. Server Response: ${response.status} ${response.statusText}.\r\n ${payload}`
          );
        }
      } catch (error) {
        logError({
          component: 'AvalonForm',
          error,
          message: 'Form draft submission failed',
          action: 'Submit',
        });
      }
    },
    [endpoint, getArkoseToken]
  );

  /** Validates `draftPayload` and sends to server if appropriate */
  useEffect(() => {
    // clone the payload to ensure values are consistent.
    // prevents race condition w/ onValueChange handler and ensures immutable state change for `priorPayload`.
    const newPayload = clone(draftPayload);

    // determine whether to state has changed to new valid state for draft storage
    const isValid = isValidPayload(newPayload, !priorPayload);
    const hasPayloadChanged = !isEqual(newPayload, priorPayload ?? {});

    if (isValid && hasPayloadChanged) {
      // immediately update priorPayload state
      setPriorPayload(newPayload);
      // Send draft to Salesforce without awaiting
      void trySubmitDraftApplication(newPayload);
    }
  }, [draftPayload, priorPayload, trySubmitDraftApplication]);

  return (
    <Form {...(form as FormDataProps)} onFormInit={onFormInit} onValueChange={onValueChange} />
  );
};
